<template>
  <main class="main home-page">
    <Categories @setProductCategory="setProductCategory"/>
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="title big">BUY CS2 SKINS</div>
          <div class="desc">Buy skins easier than ever before. Get the best deals on CS2 skins only here.</div>
          <div class="buttons">
            <router-link to="/product-list/all" class="button red">SHOP NOW</router-link>
            <button class="button" @click="$parent.openSignIn">SIGN IN</button>  
          </div>
        </div>
      </div>
      <div class="section home-products-section">
        <div class="wrapper">
          <div class="list products">
            <div class="item spec">
              <img src="./../images/home.png" class="img"/>
              <router-link to="/product-list/all" class="button red">GO TO MARKET</router-link>
            </div>
            <div class="item" v-for="(item, index) in $parent.newArrivals" :key="index">
              <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
            </div>
          </div>
        </div>
      </div>
      <div class="section offers-section">
        <div class="wrapper">
          <div class="left">
            <img src="./../images/offers.png" class="img"/>
            <div class="desc">MORE THEN</div>
            <div class="title big">1K</div>
            <div class="title">OFFERS</div>
          </div>
          <div class="right">
            <ul class="list">
              <li class="item desc">
                THERE ARE MANY VARIATIONS OF SKINS TO CHOOSE BETWEEN
              </li>
              <li class="item desc">
                CHEAP CS2 SKINS AT A DISCOUNTED PRICE
              </li>
              <li class="item desc">
                IT IS YOU CHANCE TO GET DRAGON LORE AND OTHER EPIC ITEMS!
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Categories from '../components/Categories.vue';
import ProductCard from '../components/ProductCard.vue';

export default {
  name: 'Home',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    Categories,
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      list: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    getImagePath(item) {
      const cleanItem = item.replace(/\s+/g, '');
      return require(`@/assets/types/${cleanItem.toLowerCase()}.svg`);
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>