<template>
	<footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <div class="logo">
            <img src="./../images/logoMini.svg" class="img"/>
          </div>
          <div class="text" v-if="$parent.footerRequisites">
            <div class="desc">{{$parent.footerRequisites}}</div>
          </div>
          <div class="list">
            <div class="item" v-if="$parent.footerSupportEmail">
              <img src="./../images/support.png" class="img"/>
              <div class="text">
                <div class="desc link">
                  {{$parent.footerSupportEmail }}
                </div>
                <div class="desc">
                  Support
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="nav-container">
            <ul class="nav">
              <template  v-for="(item, i) in $parent.nav">
                <li class="nav__item desc" :key="i">
                  <router-link :to="item.link" exact @click.native="up">
                    <b>{{item.title}}</b>
                  </router-link>
                </li>
              </template>
            </ul>
            <ul class="nav">
              <template  v-for="item in $parent.textPageList">
                <li class="nav__item desc" :key="item.id">
                  <a @click="$parent.goToPage(item.id)">
                    <b>{{item.title}}</b>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="social-block">
            <div class="desc" v-if="$parent.socials"><b>Social media</b></div>
            <div class="social-list" v-if="$parent.socials">
              <a target="_blank" :href="item.link" class="link" v-for="(item, i) in $parent.socials" :key="i">
                <img :src="item.img" class="img"/>
              </a>
            </div>
            <div class="desc"><b>Payment methods</b></div>
            <div class="payment-list">
              <div class="item mc">
                <img src="./../assets/mc_symbol.svg" class="img"/>
              </div>
              <div class="item">
                <img src="./../assets/visa.svg" class="img"/>
              </div>
              <div class="item">
                <img src="./../assets/3ds.png" class="img"/>
              </div>
            </div>
          </div>
          <div class="copyright">
            <div class="desc" v-if="$parent.footerCopyright">© {{ new Date().getFullYear() }} {{$parent.footerCopyright}}</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		up() {
      window.scrollTo(0, 0);
    }
	},
	mounted() {
    
  }
}
</script>
