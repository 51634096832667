<template>
	<div class="categories-menu-container" v-if="categoryOptions && categoryOptions.length">
		<ul class="categories-menu">
			<template v-for="(item, i) in categoryOptions">
				<li class="categories-menu__item desc" :key="i"  @click="$parent.setProductCategory(item)">
					{{item}}
				</li>
			</template>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Categories',
	props: [],
	data: function() {
		return {
			categoryOptions: ''
		}
	},
	methods: {
		getCategoryOptions() {
			this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
			.then((res) => {
				let newCategories = res.data.payload.types;
				this.categoryOptions = newCategories;
			})
			.catch(() => {
			})
		},
		setProductCategory(category) {
			this.$emit('setProductCategory', category)
		},
	},

	mounted: function() {
		this.getCategoryOptions();
	}
}
</script>
