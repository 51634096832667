<template>
  <main class="main product-page page-inside">
    <Categories @setProductCategory="setProductCategory"/>
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="bradcrumbs">
            <div class="desc">CS2</div>
            <router-link to="/product-list/all" class="desc">All items</router-link>
            <div class="desc link">{{ $parent.productPageItem.title }}</div>
          </div>
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="left">
                    <div class="cta">
                      <div class="cta-left">
                        <span class="desc">Buy</span>
                        <span class="desc">
                          <b>{{ $parent.productPageItem.title }}</b>
                        </span>
                      </div>
                      <div class="cta-right">
                        <button class="button red" @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)">
                          <span>ADD TO CART</span>
                        </button>
                        <div class="button">
                          <div class="desc"><span class="currency">{{currency}}</span> {{$parent.productPageItem.price}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="preview">
                      <transition name="fade">
                        <span class="title" v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">Added</span>
                      </transition>
                      <img :src="$parent.imgDomain + $parent.productPageItem.img_url" class="img"/>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text-top">
                      <img src="./../images/aim.svg" class="img"/>
                      <div class="desc">
                        <b>SKIN INFO</b>
                      </div>
                    </div>
                    <div class="text-bottom">
                      <div class="row">
                        <div class="desc">Title:</div>
                        <div class="desc">{{$parent.productPageItem.title}}</div>
                      </div>
                      <div class="row">
                        <div class="desc">Type:</div>
                        <div class="desc">{{$parent.productPageItem.type}}</div>
                      </div>
                      <div class="row">
                        <div class="desc">Quality:</div>
                        <div class="desc">{{$parent.productPageItem.quality}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Categories from '../components/Categories.vue';

export default {
  name: 'ProductPage',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    Categories
  },
  data: function() {
    return {
      
    }
  },
  created() {
  },
  mounted() {
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    }
  }
}
</script>