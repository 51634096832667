<template>
  <main class="main page-inside">
    <Categories @setProductCategory="setProductCategory"/>
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="bradcrumbs">
            <div class="desc">CS2</div>
            <div class="desc link">All items</div>
          </div>
          <div class="top">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="filters-block filters-top">
                  <div class="desc big">
                    <b>FILTERS</b>
                  </div>
                  <div class="desc link" @click="resetFilters">Clear filters</div>
                </div>
                <div class="filters-block filters-price">
                  <div class="desc big">
                    <b>PRICE</b>
                  </div>
                  <div class="val-container">
                    <div class="val min">
                      <input type="number" v-model="barMinValue"/>
                      <div class="desc">{{currency}}</div>
                    </div>
                    <div class="val max">
                      <input type="number" v-model="barMaxValue"/>
                      <div class="desc">{{currency}}</div>
                    </div>
                  </div>
                </div>
                <div class="filters-block filters-quality" v-if="qualityOptions.length">
                  <div class="desc big">
                    <b>QUALITY</b>
                  </div>
                  <label class="chekbox-label" v-for="(item, i) in qualityOptions" :key="i">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" :value="item" v-model="quality"/>
                      <div class="checkbox"></div>
                      <span class="title">{{ item }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="title" v-if="productList && !productList.length">No products!</div>
          </transition>
          <transition name="fade">
            <div class="list products" v-if="productList">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
              </div>
            </div>
          </transition>
          <pagination v-if="totalProducts" v-model="activePage" :records="totalProducts" :per-page="20" @paginate="changePage"/>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from 'vue-pagination-2';
import ProductCard from '../components/ProductCard.vue';
import Categories from '../components/Categories.vue';

export default {
  name: 'ProductListPage',
  props: ['addToCartChosenItem', 'currency', 'productCategory', 'productsFilteredBySearch', 'currencyCode'],
  components: {
    Pagination,
    ProductCard,
    Categories
  },
  data: function() {
    return {
      imgDomain: '',
      filtersIsVisible: false,
      sort: 'desc',
      category: '',
      quality: [],
      activePage: 1,
      totalProducts: null,
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        }, 
        {
          id: 'asc',
          text:'Lowest price first'
        }
      ],
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 1200,
      productList: ''
    }
  },
  watch: {
      barMinValue: function() {
      this.activePage = 1;
        this.filterProducts();
      },
      barMaxValue: function() {
      this.activePage = 1;
        this.filterProducts();
      },
      productCategory: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      quality: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      productsFilteredBySearch: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      currencyCode() {
        this.filterProducts();
      }
  },
  computed: {
    newCategoryOptions() {
      let newCategories = [...this.$parent.categoryOptions];
      newCategories.unshift('All types');
      return newCategories;
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    if (this.productCategory === 'all' || this.productCategory === 'search') {
      this.$parent.setProductCategory('');
    }
    
    this.$parent.getCategoryOptions();
    this.getQualityOptions();
    this.filterProducts();
  },
  methods: {
    selectSort(event) {
      this.activePage = 1;
      this.sort = event.id;
      this.filterProducts();
    },
    selectCategory(event) {
      this.activePage = 1;
      this.category = event.text;
      this.$parent.setProductCategory(event.text);
      this.filterProducts();
    },
    selectQuality(event) {
      this.activePage = 1;
      this.quality = event.text;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = [];
      this.category = 'All types';
      this.qualityOptions = '';
      this.$parent.getCategoryOptions();
      this.getQualityOptions();
      this.barMinValue = 0;
      this.barMaxValue = 1200;
      this.filterProducts();
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      let addCategory = '';
      if (this.productCategory == 'search') {
        addCategory = 'type=';
      } else if (this.productCategory == 'all') {
        addCategory = 'type=';
      } else if (this.category == 'All types') {
        addCategory = 'type=';
      } else {
        addCategory = 'type=' + this.productCategory
      }

      let addQuality = '';
      if (this.quality != '') {
        addQuality = '&quality=' + this.quality
      } else {
        addQuality = '';
      }
      let addPrice = '&price_from=' + this.barMinValue + '&price_till=' + this.barMaxValue;

      let addSort = '&sort=' + this.sort

      let addPage = '&page=' + this.activePage
      
      let addCurrency = '';
      addCurrency = '&currency=' + this.currencyCode;

      this.$http.get(process.env.VUE_APP_API + 'items/list?' + addCategory + addQuality + addCurrency + addPrice + addSort + addPage + '&limit=20' + '&search=' + this.productsFilteredBySearch)
      .then((res) => {
        this.productList = res.data.payload
        this.totalProducts = res.data.meta.total
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    
    getQualityOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
      .then((res) => {
        this.qualityOptions = res.data.payload.qualities;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
  }
}
</script>