<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title small">MY PROFILE</div>
          <div class="profile-top">
            <div class="profile-top-block">
              <div class="left">
                <img src="./../images/avatar.svg" class="img"/>
                <div class="info">
                  <div class="desc">namesurname</div>
                  <div class="info-steam">
                    <img src="./../images/steam.png" class="img"/>
                    <div class="desc">Steam ID:</div>
                    <div class="desc">{{ steamId }}</div>
                  </div>
                </div>
              </div>
              <div class="right">
                <button class="button red" @click="$parent.openProfileModal">EDIT PROFILE</button>
                <button class="button logout" @click="$parent.logout">
                  LOG OUT
                  <img src="./../images/logout.svg" class="img"/>
                </button>
              </div>
            </div>
            <div class="profile-top-block">
              <div class="balance">
                <div class="desc">My balance</div>
                <div class="desc big">
                  <b>
                    <span class="currency">{{$parent.currency}}</span>
                    {{ balance }}
                  </b>
                </div>
              </div>
              <button class="button red" @click="$parent.openTopup">TOP UP MY BALANCE</button>
            </div>
          </div>
          <div class="profile-form">
            <div class="tab-list">
              <button @click="activeTab = 0" :class="['item desc', {'active': activeTab == 0}]">TRANSACTION</button>
              <button @click="activeTab = 1" :class="['item desc', {'active': activeTab == 1}]">ORDER HISTORY</button>
            </div>
            <div class="profile-table" v-if="$parent.orderHistory && (activeTab == 0)">
              <div class="profile-table-wrapper profile-form-wrapper">
                <div class="table-container">
                  <table>
                    <thead>
                      <tr>
                        <td>ID</td>
                        <td>Date</td>
                        <td>Type</td>
                        <td>Status</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in $parent.depositHistory" :key="i">
                        <td>{{item.id}}</td>
                        <td>{{$parent.formatDate(item.created_at)}}</td>
                        <td>Top up balance</td>
                        <td v-if="item.status == 'Accepted'" class="green">
                          <span>{{ item.status }}</span>
                        </td>
                        <td v-else-if="item.status == 'Declined'" class="red">
                          <span>{{ item.status }}</span>
                        </td>
                        <td v-else>
                          <span>{{ item.status }}</span>
                        </td>
                        <td class="desc">
                          <b>
                            {{item.sum}} {{item.currency.code}} 
                          </b>  
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pagination" id="pagination"></div>
              </div>
            </div>
            <div class="profile-table" v-if="$parent.orderHistory && (activeTab == 1)">
              <div class="profile-table-wrapper profile-form-wrapper">
                <div class="table-container">
                  <table>
                    <thead>
                      <tr>
                        <td>ID</td>
                        <td>Date</td>
                        <td>Skin name</td>
                        <td>Status</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in $parent.orderHistory" :key="i">
                        <td>{{item.item_id}}</td>
                        <td>{{$parent.formatDate(item.created_at)}}</td>
                        <td>
                          <img :src="$parent.imgDomain + item.img_url" class="img"/>
                          {{item.title}}
                        </td>
                        <td v-if="item.status == 'Accepted'" class="green">
                          <span>{{ item.status }}</span>
                        </td>
                        <td v-else-if="item.status == 'Declined'" class="red">
                          <span>{{ item.status }}</span>
                        </td>
                        <td v-else>
                          <span>{{ item.status }}</span>
                        </td>
                        <td class="desc">
                          <b>
                             {{item.sum}} {{item.currency}}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pagination" id="pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: ['isAuth', 'appBalance', 'currencyCode'],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeTab: 0,
      balance: '0.00',
      steamId: ''
    }
  },
  watch: {
		isAuth() {
			this.getProfile();
		},
    balance() {
      this.activeTab = 1;
    },
    currencyCode() {
      this.getProfile();
    },
	},
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile?currency' + this.currencyCode)
      .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.balance = res.data.balance;
          this.$emit('setAppBalance', res.data.balance);
          this.steamId = res.data.steamId;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
  }
}
</script>