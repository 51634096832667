<template>
	<div class="menu">
		<div class="cash" v-if="$parent.isAuth">
			<img src="./../images/wallet.svg" class="img"/>
			<div class="desc">
			<span class="currency">{{ $parent.currency }}</span>
			{{ balance }}
			</div>
			<button class="button red" @click="$parent.openTopup">
			<img src="./../images/plus.svg" class="img"/>
			</button>
		</div>
		<button @click="$parent.openCart" v-if="$parent.isAuth" :class="['icon icon-cart round', {'gray': !cartContents.length}]">
			<img src="./../images/cart.svg" class="img"/>
			<div v-if="cartContents.length" class="indicator">
			{{cartContents.length}}
			</div>
		</button>
		<router-link to="/profile" class="button link-container dropdown-open round" v-if="$parent.isAuth">
			<img src="./../images/user.svg" class="img"/>
		</router-link>
		<div class="link-container" v-else>
			<div class="button" @click="openSignIn">
			<span>Sign In</span>
			</div>
			<div class="button red" @click="openSignUp">
			<span>Sign Up</span>
			</div>
		</div>
		<div class="select-container" v-if="curr && $parent.currencyOptions.length > 1">
			<Select2 class="select2 single currency-select" v-model="curr"
					@select="selectCurr($event)"
					:placeholder="curr"
					:options="$parent.currencyOptions"
			/>
		</div>
	</div>
</template>

<script>

import Select2 from 'vue3-select2-component';
export default {
	name: 'Menu',
	props: ['cartContents', 'currencyCode', 'isAuth'],
	components: {
		Select2
	},
	data: function() {
		return {
			curr: '',
			balance: '0.00'
		}
	},
	watch: {
		currencyCode(newValue) {
			this.curr = newValue
		},
		isAuth() {
			this.getBalance();
		},
	},
	mounted() {
		this.curr = this.currencyCode
		this.getBalance();
	},
	methods: {
		getBalance() {
			if (this.$parent.isAuth) {
				this.$http.get(process.env.VUE_APP_API + 'user/profile')
				.then((res) => {
					this.balance = res.data.balance;
				})
				.catch((res) => {
				if(res.response.status === 401) {
					this.$parent.openSignIn();
				}
				})
			}
		},
		selectCurr(event) {
			this.curr = event.text;
			this.changeCurrency(event.text)
			localStorage.setItem("currency", event.text);
		},
		changeCurrency(item) {
			this.$emit('changeCurrency', item)
		},
		openSignIn() {
			this.$parent.openSignIn();
		},
		openSignUp() {
			this.$parent.openSignUp();
		},
	},
}
</script>
