<template>
  <div class="modal profile-modal">
    <div class="overlay"></div>
    <div class="overlay overlay-invisible" @click="$parent.closeProfileModal"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$parent.closeProfileModal" src="./../images/close.svg"/>
            <div class='form-fields'>
              <div class="form-fields-wrapper">
                <div class="tab-list">
                  <button @click="activeTab = 0" :class="['item desc', {'active': activeTab == 0}]">PERSONAL INFORMATION</button>
                  <button @click="activeTab = 1" :class="['item desc', {'active': activeTab == 1}]">CHANGE PASSWORD</button>
                </div>
                <div class="form" v-if="activeTab == 0">
                  <div class="input-container">
                    <input type="text" placeholder="First name" v-model="name"/>
                  </div>
                  <div class="input-container">
                    <input type="text" placeholder="Last name" v-model="surname"/>
                  </div>
                  <div class="input-container">
                    <input type="email" placeholder="E-mail" v-model="email"/>
                  </div>
                  <div class="input-container">
                    <input type="tel" placeholder="Phone" v-model="phone"/>
                  </div>
                  <div class="input-container" v-if="countries">
                    <select v-model="selectedCountry">
                      <option value="" disabled selected>Countries</option>
                      <option :value="item" v-for="item in countries" :key="item.id">{{ item.title }}</option>
                    </select>
                  </div>
                  <div class="input-container">
                    <input type="text" placeholder="City" v-model="city"/>
                  </div>
                  <div class="input-container">
                    <input type="text" placeholder="Address" v-model="address"/>
                  </div>
                  <div class="input-container">
                    <input type="text" placeholder="ZIP Code" v-model="zipCode"/>
                  </div>
                  <div class="input-container long">
                    <input type="text" placeholder="Steam ID" v-model="steamId"/>
                  </div>
                  <button class="button" @click="$parent.closeProfileModal">
                    <span>CANCEL</span>
                  </button>
                  <button class="button red" @click="updateProfileData">
                    <span>SAVE CHANGES</span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                    </transition>
                    <transition name="fade">
                      <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                    </transition>
                  </div>
                </div>
                <div class="form pass" v-if="activeTab == 1">
                  <div class="input-container">
                    <input type="text" placeholder="Current password" v-model="curPass"/>
                  </div>
                  <div class="input-container">
                    <input type="text" placeholder="New password" v-model="newPass"/>
                  </div>
                  <div class="input-container">
                    <input type="text" placeholder="Confirm password" v-model="repeatPass"/>
                  </div>
                  <button class="button" @click="$parent.closeProfileModal">
                    <span>CANCEL</span>
                  </button>
                  <button class="button red" @click="updatePassword">
                    <span>SAVE CHANGES</span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                    </transition>
                    <transition name="fade">
                      <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileModal',
  props: [],
  data: function() {
    return {
      activeTab: 0,
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      email: '',
      phone: '',
      country: '',
      selectedCountry: '',
      countryId: '',
      city: '',
      address: '',
      zipCode: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      steamId: '',
      countries: []
    }
  },
  mounted() {
    this.getProfile();
    this.getCounties();
  },
  methods: {
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.email = res.data.email;
          this.countryId = res.data.country;
          this.city = res.data.city;
          this.address = res.data.address;
          this.zipCode = res.data.zip;
          this.steamId = res.data.steamId;

          if (this.countries && this.countries.length && this.countryId) {
            this.setCountry();
          }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    setCountry() {
      let mapped = this.countries.map(({
        id,
        title
      }) => ({
        id,
        title
      }));
      this.countries = mapped;
      this.selectedCountry = this.countries.find(item => item.id == this.countryId);

      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == this.selectedCountry.name) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function(item) {
            return item['title'];
          });
          let newCountriesId = arr.map(function(item) {
            return item['id'];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
          this.countries = arr;
          let mapped = this.countries.map(({
            id,
            title
          }) => ({
            id,
            title
          }));
          this.countries = mapped;

          if (this.countries && this.countries.length && this.countryId) {
            this.setCountry();
          }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    updateProfileData() {
      let profileData = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "email": this.email,
        "country": this.selectedCountry.id,
        "city": this.city,
        "address": this.address,
        "postCode": this.zipCode,
        "steamId": this.steamId,
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
  }
}
</script>